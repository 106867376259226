import React from "react"
import PropTypes from "prop-types"

import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"
import StickyRail from "@src/components/StickyRail"

import SuccessStoryCTA from "@src/components/SuccessStoryCTA"
import LoremIpsum from "@src/components/LoremIpsum"
import { ApplyUrlProvider } from "@src/hooks/useApplyUrl"

const SuccessStoryCtaPage = ({ location }) => {
  const title = "Success Story CTA - Columns"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }
  return (
    <ApplyUrlProvider
      location={location}
      attributionTopic="Small Business Loans"
    >
      <Page
        title={title}
        header={<Header />}
        headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
        footer={<Footer />}
      >
        <Columns>
          <Column width={{ desktop: 8, mobile: 12 }}>
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />

            <SuccessStoryCTA />

            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
          </Column>
          <Column width={{ desktop: 4, mobile: 12 }}>
            <StickyRail opacityAnimation>
              <SuccessStoryCTA sticky tall />
            </StickyRail>
          </Column>
        </Columns>
      </Page>
    </ApplyUrlProvider>
  )
}

SuccessStoryCtaPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default SuccessStoryCtaPage
