import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./StickyRail.module.less"
const RailVisibility = {
  IN_SCREEN: "IN_SCREEN",
  OUT_SCREEN: "OUT_SCREEN",
}

const throttle = (func, delay = 50) => {
  let inQueue = null
  return (...args) => {
    if (!inQueue) {
      inQueue = setTimeout(() => {
        func(...args)
        inQueue = null
      }, delay)
    }
  }
}

const StickyRail = ({ children, opacityAnimation, heightOffset }) => {
  const rightRail = useRef(null)
  const [stickyRail, setStickyRail] = useState(
    opacityAnimation ? RailVisibility.OUT_SCREEN : RailVisibility.IN_SCREEN
  )

  useEffect(() => {
    if (!opacityAnimation) return

    function updateStickyRailVisibility() {
      if (!rightRail.current) return setStickyRail(RailVisibility.OUT_SCREEN)
      const rectRail = rightRail.current.getBoundingClientRect()

      if (
        rectRail.top < 0 &&
        rectRail.bottom > window.innerHeight + heightOffset
      ) {
        return setStickyRail(RailVisibility.IN_SCREEN)
      }
      return setStickyRail(RailVisibility.OUT_SCREEN)
    }

    const throttleUpdate = throttle(updateStickyRailVisibility)

    throttleUpdate()
    window.addEventListener("scroll", throttleUpdate)
    return () => {
      window.removeEventListener("scroll", throttleUpdate)
    }
  }, [heightOffset, opacityAnimation])

  return (
    <div
      ref={rightRail}
      className={classNames(styles["full-height"], styles["wide-screen"], {
        [styles.visible]: stickyRail === RailVisibility.IN_SCREEN,
      })}
    >
      {children}
    </div>
  )
}

StickyRail.propTypes = {
  children: PropTypes.node.isRequired,
  heightOffset: PropTypes.number,
  opacityAnimation: PropTypes.bool,
}

StickyRail.defaultProps = {
  heightOffset: 400,
  opacityAnimation: false,
}

export default StickyRail
