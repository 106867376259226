import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Lazy from "react-lazyload"

import { Text } from "@nerdwallet/react-typography"
import Box from "@src/components/Box"
import Button from "@src/components/Button"
import { PAYMENTS_CTA_TEXT } from "@src/lib/constants"
import imageSource from "./business-owners.png"

import {
  buildElementPositionAttributes,
  buildProductShoppingAttributes,
} from "@src/lib/analytics_payloads"
import { withImpression } from "@src/lib/impression"

import styles from "./SuccessStoryCTA.module.less"
import { useApplyUrl } from "../../hooks/useApplyUrl"

const TrackedButton = withImpression(
  Button,
  {
    eventType: "product_impression",
    payload: ({ props }) => ({
      ...buildElementPositionAttributes({
        sectionName: props["data-cta-type"],
      }),
      ...buildProductShoppingAttributes(),
      entity_name: props["data-cta-type"],
    }),
  },
  {
    propWithImpression: "href",
  }
)

const CTA_TYPE = "Success Story CTA"

const SuccessStoryCTA = ({ sticky, tall }) => {
  const applyUrl = useApplyUrl({
    cta_type: CTA_TYPE,
  })

  return (
    <Box
      className={classNames(styles.wrapper, {
        [styles.sticky]: sticky,
      })}
    >
      <div
        className={classNames(styles.placeholder, {
          [styles.tall]: tall,
        })}
      >
        <Lazy height="225px">
          <img
            className={styles.image}
            src={imageSource}
            alt="Small business owners"
          />
        </Lazy>
      </div>
      <div className={styles.highlight}>
        <Text className={styles.eyebrow}>On growing their business</Text>
        <Text className={styles.quote}>
          &ldquo;When you deal with other small businesses, they’re willing to
          work with you to help you get what you need because they
          understand.&rdquo;
        </Text>
        <Text className={styles.owner}>
          Andy Yochum, Founder of Boardwalk Food Company
        </Text>
        <Text className={styles.date}>
          Funded with Fundera by NerdWallet in 2019
        </Text>

        <Box
          className={classNames(styles.buttonWrapper, {
            [styles.buttonWrapperFull]: tall,
          })}
        >
          <TrackedButton
            className={styles.button}
            href={applyUrl}
            ctaType={CTA_TYPE}
            data-cta-type={CTA_TYPE}
            rel="sponsored"
            target="_blank"
          >
            {PAYMENTS_CTA_TEXT}
          </TrackedButton>
        </Box>
      </div>
    </Box>
  )
}

SuccessStoryCTA.propTypes = {
  constrained: PropTypes.bool,
  sticky: PropTypes.bool,
  tall: PropTypes.bool,
}

SuccessStoryCTA.defaultProps = {
  constrained: false,
  sticky: false,
  tall: false,
}

export default SuccessStoryCTA
